import React, { useState } from "react";
import { FaGithub, FaInstagram, FaLinkedin, FaMapMarkerAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6"
import ProfilePic from '../profile-pic.jpg'

const LandingPage = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false);

    const handleMouseMove = (e) => {
        // Adjust position relative to center of the image
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;
        setPosition({ x, y });
    };

    const handleMouseLeave = () => {
        setPosition({ x: 0, y: 0 });
    };

    const handleClick = () => {
        alert("Easter egg found! 🎉 For now it does nothing :(");
    };


    return (
        <div className="min-h-screen bg-animated-gradient bg-cover bg-center animate-gradient-moving flex flex-col items-center justify-center space-y-4 font-sans overflow-hidden">
            <div className="flex flex-col items-center">
                <div
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    className="w-36 h-36 rounded-full border-4 border-baby-blue mb-4 shadow-glow"
                    style={{
                        transform: `translate(${position.x * 0.1}px, ${position.y * 0.1}px)`,
                        transition: 'transform 0.1s ease-out',
                    }}
                >
                    <img
                        src={ProfilePic}
                        alt="Profile"
                        className="w-full h-full rounded-full no-select"
                    />
                </div>
                <h1 className="text-3xl font-bold mb-1 text-white no-select">Miha Štih</h1>
                <div className="flex items-center text-xs text-gray-400 mt-1 mb-2 no-select">
                    <FaMapMarkerAlt className="mr-1 text-baby-blue" />
                    <span>Slovenia</span>
                </div>
                <a
                    href="mailto:mstih@proton.me"
                    className="text-baby-blue text-sm mt-2 mb-1 no-select">mstih@proton.me
                </a>
            </div>
            <hr className="w-3/4 border-baby-blue my-6" />
            <div className="flex space-x-6 text-2xl text-baby-blue">
                <a
                    href="https://github.com/mstih"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaGithub />
                </a>
                <a
                    href="https://instagram.com/miha.stih"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaInstagram />
                </a>
                <a
                    href="https://x.com/mihastih"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaXTwitter />
                </a>
                <a
                    href="https://linkedin.com/in/mihastih"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaLinkedin />
                </a>
            </div>
            {/* Hidden Button */}
            <div
                className="fixed bottom-0 left-0 w-20 h-20 p-2"
                onMouseEnter={() => setShow(true)} // Show button on hover
                onMouseLeave={() => setShow(false)} // Hide button when not hovering
            >
                {show && (
                    <button
                        onClick={handleClick}
                        className="text-white p-2 rounded transition-opacity duration-300 cursor-pointer no-select"
                    >
                        🎉
                    </button>
                )}
            </div>
        </div>
    );
};

export default LandingPage;
